import {errorInCheckoutParams} from '@wix/bi-logger-ec-site/v2/types';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';

import {
  ECOM_PLATFORM_CHECKOUT,
  cantShipToDestinationBaseParams,
  CheckoutOrigin,
  FlowType,
  getAdditionalFeesPrice,
  getCartType,
  getCatalogAppIds,
  getCustomFieldsTitles,
  getItemTypes,
  getNumberOfAdditionalFees,
  getSelectedShippingOptionIndex,
  getShippingOptionTypes,
  isCustomFieldMandatory,
  MethodType,
  StepName,
  hasPickupOption,
  toBiAmount,
  getCouponErrorCodeForBi,
  isFullyPaidByGiftCard,
  isMember,
} from '../utils/bi.util';
import {CheckoutModel} from '../models/Checkout.model';
import {CheckoutErrorModel} from '../models/CheckoutError.model';
import {CheckoutStore} from '../stores/CheckoutStore';
import {NavigationService} from './NavigationService';
import {PAYPAL, SPECS} from '../../components/Checkout/constants';
import {CheckoutSettingsService} from './CheckoutSettingsService';
import {CheckoutSettingsModel} from '../models/checkoutSettings/CheckoutSettings.model';
import {StepTag} from '../../components/Checkout/CheckoutSteps/types';

export class BIService {
  constructor(private readonly services: {siteStore: SiteStore}) {}

  public clickOnContinueShopping(checkout: CheckoutModel, origin: CheckoutOrigin = CheckoutOrigin.checkout): void {
    void this.services.siteStore.platformBiLogger.clickOnContinueShoppingFromCart({
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      itemType: getItemTypes(checkout),
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
      origin,
    });
  }

  public clickOnEditCart(checkout: CheckoutModel) {
    void this.services.siteStore.platformBiLogger.clickOnEditCart({
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      cartType: getCartType(checkout),
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      itemType: getItemTypes(checkout),
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
      origin: 'editCartButton',
    });
  }

  public sendCantShipToDestinationBIEvent(
    checkoutStore: CheckoutStore,
    checkout: CheckoutModel,
    checkoutSettings: CheckoutSettingsModel,
    navigationService: NavigationService,
    flowType: FlowType
  ): void {
    this.sendErrorInCheckoutBIEvent(
      checkoutStore,
      checkout,
      checkoutSettings,
      navigationService,
      flowType,
      cantShipToDestinationBaseParams()
    );
  }

  public sendFailedToCompleteOrderBIEvent(
    checkoutStore: CheckoutStore,
    checkout: CheckoutModel,
    checkoutSettings: CheckoutSettingsModel,
    navigationService: NavigationService,
    flowType: FlowType,
    params: Partial<errorInCheckoutParams>
  ) {
    this.sendErrorInCheckoutBIEvent(checkoutStore, checkout, checkoutSettings, navigationService, flowType, params);
  }

  public sendCheckoutErrorBIEvent(
    checkoutStore: CheckoutStore,
    checkout: CheckoutModel,
    checkoutSettings: CheckoutSettingsModel,
    navigationService: NavigationService,
    flowType: FlowType,
    params: Partial<errorInCheckoutParams>
  ) {
    this.sendErrorInCheckoutBIEvent(checkoutStore, checkout, checkoutSettings, navigationService, flowType, params);
  }

  private sendErrorInCheckoutBIEvent(
    checkoutStore: CheckoutStore,
    checkout: CheckoutModel,
    checkoutSettings: CheckoutSettingsModel,
    navigationService: NavigationService,
    flowType: FlowType,
    params: errorInCheckoutParams
  ): void {
    const catalogAppId = getCatalogAppIds(checkout);
    const contactId = checkout.buyerInfo?.contactId;
    const customFieldsTitle = getCustomFieldsTitles(checkout);
    const itemType = getItemTypes(checkout);

    void this.services.siteStore.platformBiLogger.errorInCheckout({
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId,
      channelType: checkout.channelType,
      checkoutId: checkout.id,
      contactId,
      customFieldsTitle,
      flowType,
      is_promo_code: checkoutStore.shouldShowCouponSection(),
      is_with_ewallet_payment: true,
      is_with_offline_payment: false,
      isCalculationErrors: checkout.errors.hasCalculationErrors,
      isGiftCard: checkoutStore.shouldShowGiftCardSection(),
      isMember: isMember(this.services.siteStore),
      isPolicy: checkoutSettings.areGeneralPoliciesEnabled,
      itemType,
      origin: navigationService.origin,
      originType: navigationService.originType,
      ...params,
    });
  }

  public shippingAddressSet(
    checkout: CheckoutModel,
    isBillingAddressTheSameAsShippingAddress: boolean,
    isFormValid: boolean
  ): void {
    const catalogAppId = getCatalogAppIds(checkout);
    const cartType = getCartType(checkout);
    const itemType = getItemTypes(checkout);

    void this.services.siteStore.platformBiLogger.shippingAddressSet({
      checkoutId: checkout.id,
      cartId: checkout.cartId,
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      catalogAppId,
      cartType,
      isChecked: isBillingAddressTheSameAsShippingAddress,
      item_type: itemType,
      isFormValid,
    });
  }

  public sendStageExpanded(
    checkoutStore: CheckoutStore,
    checkout: CheckoutModel,
    stepName: StepTag,
    isFirstTime: boolean
  ): void {
    const catalogAppId = getCatalogAppIds(checkout);
    const cartType = getCartType(checkout);
    const itemType = getItemTypes(checkout);

    void this.services.siteStore.platformBiLogger.checkoutPageSpecificStageExpanded({
      cartId: checkout.cartId,
      checkoutId: checkout.id,
      cartType,
      isFirstTimeUsage: isFirstTime,
      flowType: 'full flow',
      stage: stepName,
      is_member: isMember(this.services.siteStore),
      itemsCount: checkout.lineItems.length,
      catalogAppId,
      itemType,
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      hasGiftCard: checkoutStore.shouldShowGiftCardSection(),
      isGiftCardApplied: !!checkout.giftCard,
      num_of_shipping: checkout.shippingOptions.length + checkout.pickupOptions.length,
      isCartPaidOnlyWithGiftCard: checkout.payNowTotalAfterGiftCard.amount === 0 && !!checkout.giftCard,
    });
  }

  public sendEditStepClicked(checkout: CheckoutModel, stepName: StepTag): void {
    const catalogAppId = getCatalogAppIds(checkout);
    const itemType = getItemTypes(checkout);

    void this.services.siteStore.platformBiLogger.clickOnBackInCheckoutProcess({
      cartId: checkout.cartId,
      checkoutId: checkout.id,
      stage: stepName,
      catalogAppId,
      itemType,
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
    });
  }

  public deliveryMethodSet(checkout: CheckoutModel, originalShippingTitle: string): void {
    const catalogAppId = getCatalogAppIds(checkout);
    const cartType = getCartType(checkout);
    const itemType = getItemTypes(checkout);
    const shippingOptionTypes = getShippingOptionTypes(checkout);
    const selectedShippingOptionIndex = getSelectedShippingOptionIndex(checkout);

    const methodType = checkout.selectedShippingOption?.pickupInfo ? MethodType.pickup : MethodType.nonPickup;

    void this.services.siteStore.platformBiLogger.deliveryMethodSet({
      checkoutId: checkout.id,
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      catalogAppId,
      cartType,
      optionscount: checkout.shippingOptions.length + checkout.pickupOptions.length,
      selectedoptionindex: selectedShippingOptionIndex,
      itemType,
      methodType,
      selectedoptiontype: checkout.selectedShippingOption?.title,
      shippingRuleOptions: shippingOptionTypes,
      orig_shipping_method: originalShippingTitle,
    });
  }

  public clickPlaceOrder(
    checkoutStore: CheckoutStore,
    checkout: CheckoutModel,
    checkoutSettingsService: CheckoutSettingsService,
    navigationService: NavigationService,
    flowType: FlowType
  ): void {
    void this.services.siteStore.platformBiLogger.clickPlaceOrder({
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      cartType: getCartType(checkout),
      catalogAppId: getCatalogAppIds(checkout),
      channelType: checkout.channelType,
      checkoutId: checkout.id,
      contactId: checkout.buyerInfo.contactId,
      couponCode: checkout.appliedCoupon?.code,
      customFieldMandatory: isCustomFieldMandatory(checkoutSettingsService.checkoutSettings),
      customFieldsTitle: getCustomFieldsTitles(checkout),
      flowType,
      is_promo_code: checkoutStore.shouldShowCouponSection(),
      is_member: isMember(this.services.siteStore),
      is_with_ewallet_payment: true,
      is_with_offline_payment: false,
      isGiftCard: checkoutStore.shouldShowGiftCardSection(),
      isPolicy: checkoutSettingsService.checkoutSettings.areGeneralPoliciesEnabled,
      itemType: getItemTypes(checkout),
      numOfLineItems: checkout.lineItems.length,
      origin: navigationService.origin,
      originType: navigationService.originType,
      paymet_providers: this.services.siteStore.experiments.enabled(SPECS.ShouldRemovePaymentProvidersForBI)
        ? undefined
        : PAYPAL,
    });
  }

  public clickToAddCoupon(checkout: CheckoutModel, origin: CheckoutOrigin = CheckoutOrigin.checkout): void {
    void this.services.siteStore.platformBiLogger.clickToAddCoupon({
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      itemType: getItemTypes(checkout),
      origin,
    });
  }

  public clickApplyCoupon(
    couponCode: string,
    checkout: CheckoutModel,
    origin: CheckoutOrigin = CheckoutOrigin.checkout
  ): void {
    void this.services.siteStore.platformBiLogger.clickApplyCoupon({
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      couponCode,
      itemType: getItemTypes(checkout),
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
      origin,
    });
  }

  public removeACoupon(checkout: CheckoutModel, origin: CheckoutOrigin = CheckoutOrigin.checkout): void {
    void this.services.siteStore.platformBiLogger.removeACoupon({
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      couponCode: checkout.appliedCoupon?.code,
      itemType: getItemTypes(checkout),
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
      origin,
    });
  }

  public couponApplied(checkout: CheckoutModel, origin: CheckoutOrigin = CheckoutOrigin.checkout): void {
    void this.services.siteStore.platformBiLogger.couponApplied({
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      couponCode: checkout.appliedCoupon?.code,
      couponId: checkout.appliedCoupon?.id,
      type: checkout.appliedCoupon?.couponType,
      name: checkout.appliedCoupon?.name,
      itemType: getItemTypes(checkout),
      origin,
    });
  }

  public errorWhenApplyingACoupon(
    couponCode: string,
    applyCouponError: CheckoutErrorModel,
    checkout: CheckoutModel,
    origin: CheckoutOrigin = CheckoutOrigin.checkout
  ): void {
    void this.services.siteStore.platformBiLogger.errorWhenApplyingACoupon({
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      couponCode,
      errorCode: getCouponErrorCodeForBi(applyCouponError.code),
      itemType: getItemTypes(checkout),
      origin,
    });
  }

  public giftCardCheckoutClickOnCheckbox(checkout: CheckoutModel): void {
    void this.services.siteStore.platformBiLogger.giftCardCheckoutClickOnCheckbox({
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      itemType: getItemTypes(checkout),
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
    });
  }

  public giftCardCheckoutClickApply(checkout: CheckoutModel): void {
    void this.services.siteStore.platformBiLogger.giftCardCheckoutClickApply({
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      itemType: getItemTypes(checkout),
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
    });
  }

  public giftCardCheckoutRemoveCode(checkout: CheckoutModel): void {
    void this.services.siteStore.platformBiLogger.giftCardCheckoutRemoveCode({
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      giftCardId: checkout.giftCard?.id,
      itemType: getItemTypes(checkout),
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
    });
  }

  public giftCardCheckoutCodeApplied(checkout: CheckoutModel): void {
    void this.services.siteStore.platformBiLogger.giftCardCheckoutCodeApplied({
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      giftCardId: checkout.giftCard?.id,
      isCartPaidOnlyWithGiftCard: isFullyPaidByGiftCard(checkout),
      itemType: getItemTypes(checkout),
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
    });
  }

  public checkoutErrorWhenApplyingAGiftCard(applyGiftCardError: CheckoutErrorModel, checkout: CheckoutModel): void {
    void this.services.siteStore.platformBiLogger.checkoutErrorWhenApplyingAGiftCard({
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      errorCode: applyGiftCardError.code,
      itemType: getItemTypes(checkout),
    });
  }

  public checkoutMinimumOrderModalIsShownInCheckout(
    checkout: CheckoutModel,
    remaining: number,
    minimumOrderAmount: number,
    stage: StepName
  ): void {
    void this.services.siteStore.platformBiLogger.checkoutMinimumOrderModalIsShownInCheckout({
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      currency: checkout.currency,
      diffLong: toBiAmount(remaining),
      hasPickup: hasPickupOption(checkout),
      itemType: getItemTypes(checkout),
      minimumValueLong: toBiAmount(minimumOrderAmount),
      stage,
    });
  }

  public checkoutMinimumOrderClickOnGotItInErrorModalInCheckout(
    checkout: CheckoutModel,
    remaining: number,
    minimumOrderAmount: number,
    stage: StepName
  ): void {
    void this.services.siteStore.platformBiLogger.checkoutMinimumOrderClickOnGotItInErrorModalInCheckout({
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      currency: checkout.currency,
      diffLong: toBiAmount(remaining),
      itemType: getItemTypes(checkout),
      minimumValueLong: toBiAmount(minimumOrderAmount),
      stage,
    });
  }

  public checkoutErrorTrackingForDevelopers(error: string, data?: string): void {
    void this.services.siteStore.platformBiLogger.checkoutErrorTrackingForDevelopers({
      error,
      data,
    });
  }

  public clickPlaceOrderWithSubscription(
    checkout: CheckoutModel,
    defaultState: boolean,
    origin: CheckoutOrigin = CheckoutOrigin.checkout
  ): void {
    void this.services.siteStore.platformBiLogger.clickPlaceOrderWithSubscription({
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      default_state: defaultState,
      itemType: getItemTypes(checkout),
      numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
      origin,
    });
  }

  public checkoutClickOnCheckoutPolicies(checkout: CheckoutModel, linkLocation: string, policyType: string) {
    void this.services.siteStore.platformBiLogger.checkoutClickOnCheckoutPolicies({
      catalogAppId: getCatalogAppIds(checkout),
      checkoutId: checkout.id,
      linkLocation,
      policyType,
    });
  }

  public navigateToOldCheckout(reason: string, checkoutId?: string) {
    void this.services.siteStore.platformBiLogger.checkoutRedirectFromOoiCheckoutForDev({checkoutId, reason});
  }

  public loadCheckoutFailed() {
    void this.services.siteStore.platformBiLogger.checkoutLoadingCheckoutPageFailForDev({});
  }

  public checkoutPageLoad(
    checkoutStore: CheckoutStore,
    checkout: CheckoutModel,
    checkoutSettings: CheckoutSettingsModel,
    navigationService: NavigationService
  ) {
    const itemType = getItemTypes(checkout);
    void this.services.siteStore.platformBiLogger.checkoutPageLoad({
      isOutOfIframe: true,
      additionalFeesPrice: getAdditionalFeesPrice(checkout),
      appName: ECOM_PLATFORM_CHECKOUT,
      cartId: checkout.cartId,
      cartType: getCartType(checkout),
      catalogAppId: getCatalogAppIds(checkout),
      channelType: checkout.channelType,
      checkoutId: checkout.id,
      contactId: checkout.buyerInfo.contactId,
      customFieldsTitle: getCustomFieldsTitles(checkout),
      first_stage: StepName.PLACE_ORDER_FAST_FLOW,
      flowType: FlowType.fastFlow,
      is_member: isMember(this.services.siteStore),
      is_promo_code: checkoutStore.shouldShowCouponSection(),
      is_with_ewallet_payment: true,
      is_with_offline_payment: false,
      isGiftCard: checkoutStore.shouldShowGiftCardSection(),
      isPolicy: checkoutSettings.areGeneralPoliciesEnabled,
      itemType,
      origin: navigationService.origin,
      originType: navigationService.originType,
      paymentProviders: this.services.siteStore.experiments.enabled(SPECS.ShouldRemovePaymentProvidersForBI)
        ? undefined
        : PAYPAL,
      shippingCountry: checkout.shippingDestination?.address.country,
    });
  }
}

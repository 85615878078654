import {FedopsInteractions, FORMS_CHECKOUT_NAMESPACE, FORMS_TEMPLATE_IDS} from '../../components/Checkout/constants';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {FormsStoreProps} from '../../types/app.types';
import {FormKind, initFormController} from '@wix/form-viewer/controller';
import {NavigationService} from '../services/NavigationService';
import {CheckoutService} from '../services/CheckoutService';

export class FormsStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly checkoutService: CheckoutService;
  private areFormsLoaded!: boolean;
  private readonly updateComponent: () => void;

  constructor({
    flowAPI,
    navigationService,
    checkoutService,
    updateComponent,
  }: {
    flowAPI: ControllerFlowAPI;
    navigationService: NavigationService;
    checkoutService: CheckoutService;
    updateComponent: () => void;
  }) {
    this.flowAPI = flowAPI;
    this.checkoutService = checkoutService;
    this.updateComponent = updateComponent;
    this.areFormsLoaded = false;

    if (navigationService.isPickupFlow) {
      void this.initForms();
    }
  }

  /* istanbul ignore next: test forms */
  private async initForms() {
    try {
      this.flowAPI.fedops.interactionStarted(FedopsInteractions.InitFormsInteraction);
      await initFormController(this.flowAPI, {
        formId: [FORMS_TEMPLATE_IDS.EMAIL, FORMS_TEMPLATE_IDS.CONTACT, FORMS_TEMPLATE_IDS.ADDITIONAL_INFO],
        formKind: FormKind.EXTENSION,
        namespace: FORMS_CHECKOUT_NAMESPACE,
      });
      this.flowAPI.fedops.interactionEnded(FedopsInteractions.InitFormsInteraction);
      this.areFormsLoaded = true;
      this.checkoutService.checkout && this.updateComponent();
    } catch (error) {
      console.error(error);
    }
  }

  public toProps(): FormsStoreProps {
    return {
      areFormsLoaded: this.areFormsLoaded,
    };
  }
}

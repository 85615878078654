import {NavigationService} from '../services/NavigationService';
import {NavigationStoreProps} from '../../types/app.types';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutService} from '../services/CheckoutService';
import {BIService} from '../services/BIService';
import {CheckoutOrigin} from '../utils/bi.util';
import {APP_DEFINITION_ID, STORES_APP_DEF_ID} from '@wix/wixstores-client-core';

export class NavigationStore {
  private readonly biService: BIService;
  private readonly navigationService: NavigationService;
  private readonly siteStore: SiteStore;
  private readonly checkoutService: CheckoutService;

  constructor({
    navigationService,
    siteStore,
    checkoutService,
    biService,
  }: {
    navigationService: NavigationService;
    siteStore: SiteStore;
    checkoutService: CheckoutService;
    biService: BIService;
  }) {
    this.navigationService = navigationService;
    this.siteStore = siteStore;
    this.checkoutService = checkoutService;
    this.biService = biService;
  }

  public navigateToOldCheckoutForUnsupportedFlow() {
    if (!this.isCurrentFlowSupported()) {
      this.biService.navigateToOldCheckout('not fast flow');
      this.navigationService.navigateToOldCheckout();
    }
  }

  public navigateToOldCheckoutForUnsupportedItems() {
    if (!this.areAllCheckoutItemsFromStores()) {
      this.biService.navigateToOldCheckout('non-stores', this.checkoutService.checkout.id);
      this.navigationService.navigateToOldCheckout();
    }
  }

  private isCurrentFlowSupported() {
    const isFastFlow = Boolean(this.navigationService.appSectionParams.cashierPaymentId);

    const isVisitorPickupFlow = this.navigationService.isPickupFlow && !this.siteStore.usersApi.currentUser?.loggedIn;

    return isFastFlow || isVisitorPickupFlow;
  }

  private areAllCheckoutItemsFromStores() {
    return (
      this.checkoutService.checkout.lineItems.filter(
        ({catalogAppId}) => ![APP_DEFINITION_ID, STORES_APP_DEF_ID].includes(catalogAppId)
      ).length === 0
    );
  }

  private readonly clickOnContinueShopping = (origin?: CheckoutOrigin) => {
    this.biService.clickOnContinueShopping(this.checkoutService.checkout, origin);
  };

  private readonly clickOnBackToSite = () => {
    this.biService.clickOnContinueShopping(this.checkoutService.checkout);
    return this.navigationService.navigateToContinueShopping();
  };

  private readonly clickOnReturnToCart = async () => {
    return this.navigationService.navigateToCart(this.checkoutService.checkout.cartId);
  };

  private readonly clickOnEditCart = () => {
    this.biService.clickOnEditCart(this.checkoutService.checkout);
  };

  private readonly onLogin = (): void => {
    this.navigationService.navigateToOldCheckout();
  };

  public toProps(): NavigationStoreProps {
    return {
      clickOnBackToSite: this.clickOnBackToSite,
      clickOnContinueShopping: this.clickOnContinueShopping,
      clickOnReturnToCart: this.clickOnReturnToCart,
      clickOnEditCart: this.clickOnEditCart,
      continueShoppingUrl: this.navigationService.continueShoppingUrl,
      editCartUrl: this.navigationService.editCartUrl,
      trackEvent: (...args) => {
        this.siteStore?.trackEvent(...args);
      },
      onLogin: this.onLogin,
    };
  }
}
